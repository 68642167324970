import React from 'react';
import Layout from '../components/layout';
import Seo from '../components/seo';
import Image from '../components/image';
import Animation from '../images/ani-graph.inline.svg';
import Bgimg from '../images/ls-tick.svg';

const AboutPage = () => {
    return (
        <Layout>
            <Seo
                title="About"
                description="Life Insurance and Family Planning"
            />
            <div className="app-title">
                <div className="title-txt">
                    <h1
                        data-sal="slide-up"
                        data-sal-duration="500"
                        data-sal-delay="400"
                        data-sal-easing="easeOutCubic"
                    >
                        About
                    </h1>
                    <p
                        data-sal="slide-up"
                        data-sal-duration="500"
                        data-sal-delay="600"
                        data-sal-easing="easeOutCubic"
                    >
                        Three Generation Family Business
                    </p>
                </div>
                <div className="app-ani">
                    <Animation />
                </div>
            </div>
            <div className="content">
                <div className="container">
                    <div className="grid">
                        <div className="col-lg-12">
                            <div className="about-intro">
                                <h1>We Build Long Term Relationships</h1>
                                <p>
                                    We work with our clients to help them
                                    achieve their short and long term financial
                                    goals, as well as ensuring they are
                                    protected against the financial implications
                                    of events such as serious illness, injury or
                                    death. Collectively, our advisers have over
                                    170 years of experience in the financial
                                    planning industry and will invest the time
                                    to build trusted, long term relationships.
                                </p>
                            </div>
                            <div className="about-values">
                                <h1
                                    data-sal="slide-up"
                                    data-sal-duration="300"
                                    data-sal-easing="easeOutCubic"
                                >
                                    Our Values
                                </h1>
                                <ul className="values">
                                    <li
                                        data-sal="slide-up"
                                        data-sal-duration="300"
                                        data-sal-easing="easeOutCubic"
                                    >
                                        <figure>
                                            <Image
                                                src="icon-entitle.svg"
                                                alt="Entitled To"
                                            />
                                        </figure>
                                        <span>
                                            We will work to maximise your
                                            entitlements
                                        </span>
                                    </li>
                                    <li
                                        data-sal="slide-up"
                                        data-sal-duration="300"
                                        data-sal-easing="easeOutCubic"
                                    >
                                        <figure>
                                            <Image
                                                src="icon-objective.svg"
                                                alt="Objective"
                                            />
                                        </figure>
                                        <span>
                                            We're Autonomous &amp; Objective
                                        </span>
                                    </li>
                                    <li
                                        data-sal="slide-up"
                                        data-sal-duration="300"
                                        data-sal-easing="easeOutCubic"
                                    >
                                        <figure>
                                            <Image
                                                src="icon-clients.svg"
                                                alt="Clients"
                                            />
                                        </figure>
                                        <span>
                                            We Protect Our Clients &amp; Their
                                            Assets
                                        </span>
                                    </li>
                                    <li
                                        data-sal="slide-up"
                                        data-sal-duration="300"
                                        data-sal-easing="easeOutCubic"
                                    >
                                        <figure>
                                            <Image
                                                src="icon-collab.svg"
                                                alt="Collaborative"
                                            />
                                        </figure>
                                        <span>
                                            Collaborative Approach, Varied
                                            Opinions
                                        </span>
                                    </li>
                                    <li
                                        data-sal="slide-up"
                                        data-sal-duration="300"
                                        data-sal-easing="easeOutCubic"
                                    >
                                        <figure>
                                            <Image
                                                src="icon-trust.svg"
                                                alt="Trust"
                                            />
                                        </figure>
                                        <span>
                                            Trustworthy, Good Relationships
                                        </span>
                                    </li>
                                    <li
                                        data-sal="slide-up"
                                        data-sal-duration="300"
                                        data-sal-easing="easeOutCubic"
                                    >
                                        <figure>
                                            <Image
                                                src="icon-help.svg"
                                                alt="Achieve"
                                            />
                                        </figure>
                                        <span>We Help Achieve Your Goals</span>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="breaker">
                <Image src="ls-group.jpg" alt="Lifespan Group - Life Insurance" />
            </div>
            <div className="outro">
                <div className="container">
                    <div className="grid">
                        <div className="col-lg-12">
                            <div className="outrotxt outro-full">
                                <h1
                                    data-sal="slide-up"
                                    data-sal-duration="300"
                                    data-sal-easing="easeOutCubic"
                                >
                                    Why Choose Us
                                </h1>
                                <ul className="why">
                                    <li
                                        data-sal="slide-up"
                                        data-sal-duration="300"
                                        data-sal-easing="easeOutCubic"
                                        style={{
                                            backgroundImage: `url(${Bgimg})`,
                                        }}
                                    >
                                        We care about what we do and always work
                                        towards achieving the best outcomes for
                                        our clients.
                                    </li>
                                    <li
                                        data-sal="slide-up"
                                        data-sal-duration="300"
                                        data-sal-easing="easeOutCubic"
                                        style={{
                                            backgroundImage: `url(${Bgimg})`,
                                        }}
                                    >
                                        Competent insurance advice involves an
                                        understanding of precisely what is
                                        required to protect you and your family
                                        against unexpected events such as ill
                                        health, injury and death.
                                    </li>
                                    <li
                                        data-sal="slide-up"
                                        data-sal-duration="300"
                                        data-sal-easing="easeOutCubic"
                                        style={{
                                            backgroundImage: `url(${Bgimg})`,
                                        }}
                                    >
                                        Proficient financial planning simplifies
                                        your finances and sets financial
                                        milestones to help you achieve your life
                                        goals. We recommend investments that are
                                        in the best interests of our clients.
                                    </li>
                                    <li
                                        data-sal="slide-up"
                                        data-sal-duration="300"
                                        data-sal-easing="easeOutCubic"
                                        style={{
                                            backgroundImage: `url(${Bgimg})`,
                                        }}
                                    >
                                        We thoroughly enjoy building long term
                                        relationships with our clients and their
                                        families.
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Layout>
    );
};

export default AboutPage;
